// fetchData.js
import axios from 'axios';


const fetchData = async (setstoreproduct, navigate) => {
    const savedLocations = JSON.parse(localStorage.getItem('savedLocations'));
    const selectedLocation = savedLocations?.find((location) => location.check);
    try {

        const lati = selectedLocation?.mpData?.location?.lat;
        const long = selectedLocation?.mpData?.location?.lng;
        const token = localStorage.token;

        const response = await axios.get(`https://api.vapedelivery.com/api/main/avail-store?lat=${lati}&lng=${long}`, {
            headers: {
                'Authorization': `Bearer ${token}` // Set the token in the Authorization header
            },
            withCredentials: false,
        });
        // setstoreproduct({ ...re.sponse.data, location: selectedLocation.mpData, frmData: selectedLocation.frmData });
        return response.data;

    } catch (error) {
        // navigate('/product');

        return {};

    }
};

export default fetchData;
