import {
    Success, Fail, getProductSuccess,
    getProductFail

} from "../constants";

export const initialState = {
    user: null,
    tokens: {},
    userCreated: false,
    passwordUpdate: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Success:
            return {
                ...state,
                apiresult: action.payload,
            };
        case Fail:
            return {
                ...state,
                apiresult: action.payload.message,
            };
        case getProductSuccess:
            return {
                ...state,
                getProduct: action.payload,
            };
        case getProductFail:
            return {
                ...state,
                getProduct: action.payload.message,
            };




        default:
            return state;
    }

}