import React, { createContext, useContext, useState } from 'react';

// Create a context
const AppContext = createContext();

// Create a context provider
export const AppContextProvider = ({ children }) => {
    const [value, setValue] = useState([])
    const [user, setUser] = useState([])
    const [storeproduct, setstoreproduct] = useState([])

    return (
        <AppContext.Provider value={{ value, setValue, user, setUser, storeproduct, setstoreproduct }}>
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to use the context
export const useAppContext = () => useContext(AppContext);