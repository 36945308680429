import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppContextProvider } from './AppContextProvider';
import store from './Redux/store'
import { Provider } from 'react-redux';
import { ThemeProvider } from "@material-tailwind/react";

const cloverKey = process.env.REACT_APP_CLOVER_KEY;

// Ensure the Clover SDK script has loaded before using it
window.onload = () => {
    if (window.Clover && cloverKey) {
        window.clover = new window.Clover(cloverKey);
    } else {
        console.error("Clover SDK not loaded or Clover Key is missing");
    }
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <AppContextProvider>
        <ThemeProvider>
            <App />
        </ThemeProvider>
        </AppContextProvider>
    </Provider>

);


reportWebVitals();
