import React, { useEffect, useState } from 'react'
import { useAppContext } from '../AppContextProvider';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

export default function ContactUS() {
    // const { value, setValue } = useAppContext();
    const [contectinfo, setContectinfo] = useState({
        name: '',
        email: '',
        message: '',
    });
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const data = {
        name: contectinfo.name,
        email: contectinfo.email,
        message: contectinfo.message
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setContectinfo({
    //         ...contectinfo,
    //         [name]: value,
    //     });
    // };

    // axios.post('http://ec2-3-17-166-251.us-east-2.compute.amazonaws.com/api/main/mail', data, {
    //     withCredentials: false
    // }).then(response => {
    //     Swal.fire({
    //         icon: 'success',
    //         title: 'Email Sent.',
    //         showConfirmButton: false,
    //         timer: 1500
    //     });
    // })
    return (
        <div className='gap-y-6 md:w-3/5 md:items-center'>
            <div className='p-6 my-4 flex flex-col self-center items-center '>
                <p className='text-[#916CC0] font-bold pt-3 text-xl'>GET IN TOUCH</p>
                <p className=' md:w-[55%] text-gray-800 py-4 self-center font-bold md:text-5xl'>Contact information</p>
                <p className=' md:w-3/5  pb-4 text-gray-600 font-medi text-xl'>Founded in 2023, Vapedelivery began as a family-owned business focused on high-quality products and exceptional customer service. While we’ve grown and evolved, our commitment to innovation, quality, and customer satisfaction remains unchanged.

                </p>
            </div>


            <div className=' md:place-content-center md:self-center self-center md:flex gap-4 py-4'>
                <div className='md:w-1/2'>
                    <div className='p-4 rounded bg-white shadow text-left'>
                        <p className='text-gray-600 font-bold py-2 text-2xl'>Store Location </p>
                        <p className=' pb-4 text-gray-600 font-medi text-md'><b>Address: </b>1002 liberty St, Erie, PA 16502</p>
                        {/* <p className=' pb-4 text-gray-600 font-medi text-md'><b>Call Us:</b> (814) 520-5184</p> */}
                    </div>
                    <div className='p-4 rounded  mt-4 bg-white shadow text-left'>
                        <p className='text-gray-600 font-bold py-4 text-2xl'>Store Timing</p>
                        <p className=' pb-4 text-gray-600 font-medi text-md'><b>Monday:</b> 8:00 – 17:00</p>
                        <p className=' pb-4 text-gray-600 font-medi text-md'><b>Tuesday:</b> 8:00 – 17:00</p>
                        <p className=' pb-4 text-gray-600 font-medi text-md'><b>Wednesday:</b> 8:00 – 17:00</p>
                        <p className=' pb-4 text-gray-600 font-medi text-md'><b>Thursday:</b> 8:00 – 17:00</p>
                        <p className=' pb-4 text-gray-600 font-medi text-md'><b>Friday:</b> 8:00 – 17:00</p>
                        <p className=' pb-4 text-gray-600 font-medi text-md'><b>Saturday:</b> 10:00 – 16:00</p>
                        <p className=' pb-4 text-gray-600 font-medi text-md'><b>Sunday:</b> CLOSED</p>

                    </div>
                </div>
                <div className="md:w-1/2 mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700 text-left" >
                    {/* <div className="w-full pt-1 pb-5">
              <div className="bg-orange-500 text-white overflow-hidden rounded-full w-20 h-20 -mt-16 mx-auto shadow-lg flex justify-center items-center">
                <i className="mdi mdi-credit-card-outline text-3xl"></i>
              </div>
            </div> */}
                    <div className="mb-10">
                        <h1 className=" font-bold text-xl uppercase">Send us a message</h1>
                    </div>
                    <div className="mb-3">
                        <label className="font-bold text-sm mb-2 ml-1">Your name</label>
                        <input className="w-full px-3 py-2 mb-1 border-2 rounded-md focus:outline-none focus:border-orange-500 border-gray-200  transition-colors" placeholder="John Smith" type="text" />
                    </div>
                    <div className="mb-3">
                        <label className="font-bold text-sm mb-2 ml-1">Your email</label>
                        <input className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="Email" type="email" />
                    </div>
                    <div className="">
                        <label className="font-bold text-sm mb-2 ml-1">Your message</label>
                        <textarea row="5" className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="Message" type="text" />
                    </div>
                    <button className="block w-full  mx-auto bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-lg px-3 py-3 font-semibold"><i className="mdi mdi-lock-outline mr-1"></i>Submit</button>
                </div>
            </div>

        </div>
    )
}






// <div className='p-6 my-4 flex flex-col self-center items-center  '>
// <p className='text-[#916CC0] font-bold pt-3 text-md'>HOW TO GET TO US</p>
// <p className=' md:w-[55%] text-gray-800 py-4 self-center font-bold text-5xl'>Find our store</p>
// {/* <p className=' md:w-2/5  pb-4 text-gray-600 font-medi text-xl'>Our company was founded in 1960. We work daily to become better and we are ready to share best practices.</p> */}
// </div>
// <div className='rounded mb-6'><iframe width="100%" height="600" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(Vape%20delivery)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps trackers</a></iframe></div>