import React, { useEffect,  } from 'react'
import { useAppContext } from '../AppContextProvider';

import agreement from '../../src/Assets/agreement-1.png'
import employee from '../../src/Assets/employee.png'
import expand from '../../src/Assets/expand.png'
import product from '../../src/Assets/vaping-shop-about-us.png'
import team from '../../src/Assets/team-meeting.png'
import vape from '../../src/Assets/vaping-shop-abou-us.png'
import { useLocation } from 'react-router-dom';

export default function AboutUs() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className=" text-start ">
            <div className='p-6  flex text-center flex-col self-center items-center mt-16 max-w-screen-xl'>
                <p className='text-[#916CC0] font-bold py-4 text-2xl'>OUR STORY</p>
                <p className=' md:w-[55%] text-gray-800 py-4  font-bold text-5xl'>About Our Delivery</p>
                <p className=' md:w-[50%]  pb-4 text-gray-600 font-medi text-xl'>Learn about our company's vision, values, and passion for delivering top-quality vaping products and outstanding customer service.</p>
            </div>

            <div className={'max-w-screen-xl md:flex flex-wrap my-6 items-center py-6  justify-center gap-4  w-full '}>
                <div className="p-4  w-full md:w-[28%]  flex flex-wrap items-center justify-center cursor-pointer">
                    <div className="p-8 w-full bg-white   rounded-xl shadow-gray-300 shadow-2xl group">
                        <div className="  flex items-center justify-center  transition-transform">
                            <img className=" w-24   " src={employee} alt="" />
                        </div>
                        <div className="text-center text-gray-700 ">
                            <p className="block font-semibold text-xl py-1 pt-3">Experienced team</p>
                            <p className="block font-normal text-md py-4"> At Vapor Punk LLC, our dedicated team offers exceptional service and expert advice. With a passion for vaping and customer satisfaction, we guide you through our extensive product selection.</p>
                        </div>
                    </div>
                </div >
                <div className="p-4 w-full md:w-[28%]  flex flex-wrap items-center justify-center cursor-pointer">
                    <div className=" p-8  w-full bg-white   rounded-xl shadow-gray-300 shadow-2xl group">

                        <div className=" flex items-center justify-center  transition-transform">

                            <img className=" w-24   " src={expand} alt="" />
                        </div>
                        <div className="text-center text-gray-700  px-2">
                            <p className="block font-semibold text-xl py-1 pt-3">Broad variety</p>
                            <p className="block font-normal text-md py-4">Discover premium electronic cigarettes, high-quality e-liquids, and stylish accessories at Vapor Punk LLC, catering to enthusiasts and budget-conscious customers alike.</p>


                        </div>
                    </div>
                </div >
                <div className="p-4 w-full md:w-[28%]  flex flex-wrap items-center justify-center cursor-pointer">
                    <div className=" p-8  w-full bg-white   rounded-xl shadow-gray-300 shadow-2xl group">
                        <div className=" flex items-center justify-center  transition-transform">
                            <img className=" w-24   " src={agreement} alt="" />
                        </div>
                        <div className="text-center text-gray-700 px-2">
                            <p className="block font-semibold text-xl py-1 pt-3">Quality guarantee</p>
                            <p className="block font-normal text-md py-4">Shop confidently with our rigorously tested products, ensuring performance, safety, and durability for your vaping experience. Your satisfaction is our priority.</p>

                        </div>
                    </div>
                </div >
            </div>

            <div className='max-w-screen-xl md:flex  mt-6  gap-2 bg-white  p-2'>
                <div className='md:w-1/2 flex gap-2 flex-col justify-center items-center 3xl:h-1/2  p-4'>
                    <div className='   rounded-full flex justify-center items-center'>
                        <img className="w-full " style={{ height: '340px', width: '550px' }} src={team} />
                    </div>
                    <div className='flex flex-wrap justify-center w-full gap-3 '>
                        <img className="w-1/2" style={{ height: '220px', width: '270px' }} src={vape} />
                        <img className="w-1/2" style={{ height: '220px', width: '270px' }} src={product} />
                    </div>
                </div>
                <div className='md:w-1/2 p-2 py-6'>
                    <p className='cursor-pointer text-start text-[#916CC0] font-bold pt-3 text-xl'>WHAT WE DO</p>
                    <p className=' md:w-full text-start text-gray-800 py-4 self-center font-bold md:text-4xl'>Welcome to our vaping store</p>

                    <p className='py-4 md:w-4/5 text-start font-normal text-gray-600 text-xl'>Welcome to our store! There are many electronic cigarettes, e-liquids, accessories and vaping accessories in our store. Our customers appreciate us for a wide range of products. In our network there are products both premium segment and middle and budget categories at the same time.</p>
                    {/* <button className="flex justify-between shadow my-3  rounded-full bg-orange-500 text-white text-xs font-bold px-4 py-1 leading-none flex items-center  hover:bg-orange-600  hover:text-white duration-500 hover:scale-105"
                      onClick={() => { selectRecordHandler(item, index) }}
                    >
                        <span className="block p-2 text-xl">Shop Now</span>
                    </button> */}
                </div>
            </div>
            {/* <div className='w-full flex items-center pb-12'>
                <img className="w-1/4" style={{ height: '250px', }} src={vape} />
                <img className="w-1/4" style={{ height: '250px', }} src={vape} />
                <img className="w-1/4" style={{ height: '250px', }} src={vape} />
                <img className="w-1/4" style={{ height: '250px', }} src={vape} />
            </div> */}
        </div>
    )
}
