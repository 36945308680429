import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import axios from 'axios';
import { useAppContext } from '../AppContextProvider';
import Swal from 'sweetalert2';
const libraries = ['places'];

export default function SaveLocation({ }) {
    let navigate = useNavigate();
    const [formData, setFormData] = useState(false);
    const [savedLocation, setsavedLocation] = useState(true);
    const { storeproduct, setstoreproduct } = useAppContext()
    const [savedLocations, setSavedLocations] = useState(
        () => {
            const saved = localStorage.getItem('savedLocations');
            return saved ? JSON.parse(saved) : [];
        }
    );
    useEffect(() => {
        // localStorage.removeItem('savedLocations');
        // Retrieve saved location name from local storage on component mount
        // const savedName = localStorage.getItem('savedLocationName');
        // const savedName = localStorage.getItem('savedLocationName');
        // if (savedName) {
        //     setSavedLocationName(savedName);
        // }
    }, [storeproduct]);


    const [searchBox, setSearchBox] = useState(null);
    const [mapData, setMapData] = useState(null);

    const onLoad = ref => {
        setSearchBox(ref);
    };
   


    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        const place = places[0];
        if (place) {
            const location = place.geometry.location;
            const name = place.name;
            const addressComponents = place.address_components;
            // Extract the state from address components
            const stateComponent = addressComponents.find(component =>
                component.types.includes('administrative_area_level_1')
            );
            const state = stateComponent ? stateComponent.long_name : null;
            // Extract the ZIP code from address components
            const cityComponent = addressComponents.find(component =>
                component.types.includes('locality')
            );
            const city = cityComponent ? cityComponent.long_name : null;
            const zipComponent = addressComponents.find(component =>
                component.types.includes('postal_code')
            );
            const zip = zipComponent ? zipComponent.long_name : null;
            var tax = 0
            if (state == "Pennsylvania") {
                tax = 6.0
            } else if (state == "Texas") {
                tax = 6.25
            } else {
                tax = 6
            }
            // setFormData({ name, state, zip })
            setMapData({ location: { lat: location.lat(), lng: location.lng() }, name, state, zip, tax, city });
            setsavedLocation(false);
        }
    };



    const saveLocation = (location) => {
        const data = { frmData: formData, mpData: mapData, id: (mapData.location.lat + mapData.location.lng), check: true };
        // Update saved locations, set the new one as checked and others as unchecked
        const updatedSavedLocations = savedLocations.map((item) => ({
            ...item,
            check: false
        }));

        // Check if the location already exists
        const locationExists = updatedSavedLocations.some((x) => x.mpData.location.lat === mapData.location.lat && x.mpData.location.lng === mapData.location.lng);

        if (locationExists) {
            Swal.fire({
                icon: 'warning',
                title: `Location already exists`,
                showConfirmButton: true,
            });
            setsavedLocation(true);
        } else {
            updatedSavedLocations.push(data);
            handelSubmit(data);
            localStorage.setItem('savedLocations', JSON.stringify(updatedSavedLocations));
            setSavedLocations(updatedSavedLocations);
            setsavedLocation(true);
            navigate('/product');
        }
    };

    const deleteLocation = (indexToDelete) => {
        const locationToDelete = savedLocations[indexToDelete];
        const updatedSavedLocations = savedLocations.filter((_, index) => index !== indexToDelete);
        // Update the localStorage
        localStorage.setItem('savedLocations', JSON.stringify(updatedSavedLocations));
        setSavedLocations(updatedSavedLocations);

        // Check if the location to be deleted is the currently selected one
        if (locationToDelete.check) {
            // If it is, set the first location as selected if there are any locations left
            if (updatedSavedLocations.length > 0) {
                const newSelectedLocation = { ...updatedSavedLocations[0], check: true };
                updatedSavedLocations[0] = newSelectedLocation;
                localStorage.setItem('savedLocations', JSON.stringify(updatedSavedLocations));
                setSavedLocations(updatedSavedLocations);
            } else {
                setsavedLocation(false);
            }
        }
        setsavedLocation(true);
        navigate('/product');
    };

    const handelSubmit = (e) => {
        const lati = e?.mpData.location?.lat;
        const long = e?.mpData.location?.lng;
        const token = localStorage.token
        const updatedSavedLocations = savedLocations.map((item) => {
            if (item.id === (e?.mpData.location.lat + e?.mpData.location.lng)) {
                return { ...item, check: true };
            }
            return { ...item, check: false };
        });
        localStorage.setItem('savedLocations', JSON.stringify(updatedSavedLocations));
        setSavedLocations(updatedSavedLocations);



        axios.get(`https://api.vapedelivery.com/api/main/avail-store?lat=${lati}&lng=${long}`, {
            headers: {
                'Authorization': `Bearer ${token}` // Set the token in the Authorization header
            },
            withCredentials: false,

        }).then(response => {
            setstoreproduct({ ...response.data, location: e.mpData, frmData: e.frmData });

            navigate('/product')
            setsavedLocation(true)
        }).catch(error => {
            const location = e.mpData
            const frmData = e.frmData
            setstoreproduct(location, frmData);
            navigate('/product')
            // navigate(-1);
        });

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    return (
        <div className="fixed top-0  scrollbar overflow-y-auto right-0 h-full top-0 z-20 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white rounded-lg shadow w-full md:w-2/3 xl:w-1/3">
                <svg onClick={() => { navigate('/product'); setsavedLocation(true) }} className='absolute top-3 end-2.5 cursor-pointer rounded-full hover:shadow-md' width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16.3242" cy="16.0781" r="16" fill="#FF4141" />
                    <path d="M21.6654 10.7349C21.335 10.4045 20.7994 10.4045 20.469 10.7349L16.3242 14.8797L12.1793 10.7349C11.8489 10.4045 11.3133 10.4045 10.9829 10.7349C10.6526 11.0653 10.6526 11.6009 10.9829 11.9313L15.1278 16.0761L10.9829 20.221C10.6526 20.5513 10.6526 21.087 10.9829 21.4173C11.3133 21.7477 11.8489 21.7477 12.1793 21.4173L16.3242 17.2725L20.469 21.4173C20.7994 21.7477 21.335 21.7477 21.6654 21.4173C21.9957 21.087 21.9957 20.5513 21.6654 20.221L17.5205 16.0761L21.6654 11.9313C21.9957 11.6009 21.9957 11.0653 21.6654 10.7349Z" fill="white" />
                </svg>
                {savedLocation ?
                    <div className="p-4 md:p-5 mt-2 w-full">
                        <h3 className="mb-5 text-xl text-center font-bold text-gray-700 ">Add new address                        </h3>
                        <LoadScript
                            googleMapsApiKey="AIzaSyDTF_IHcL1fDOskOfGnX_cYjcc2udxBfRU"
                            libraries={libraries}
                            loadingElement={<>Loading...</>}
                        >
                            <GoogleMap
                                id="search-box-example"
                                mapContainerStyle={{
                                    height: '40px',
                                    marginBottom: '0.5rem',
                                    display: 'flex',
                                    width: '100%',
                                    borderRadius: '0.25rem',
                                    background: 'white'
                                }}
                                className="mb-2 flex w-full  p-1 rounded border-2 border-gray-200"
                                zoom={0}

                            >
                                <StandaloneSearchBox
                                    onLoad={onLoad}
                                    onPlacesChanged={onPlacesChanged}
                                >
                                    <input
                                        type="text"
                                        placeholder="Address"
                                        style={{
                                            boxSizing: 'border-box',
                                            border: '1px solid transparent',
                                            width: '100%',
                                            height: '40px',
                                            padding: '0 12px',
                                            borderRadius: '0.25rem',
                                            borderColor: '#e5e7eb',
                                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                            fontSize: '14px',
                                            outline: 'none',
                                            textOverflow: 'ellipses',
                                            position: 'absolute',
                                        }}
                                    />
                                </StandaloneSearchBox>
                            </GoogleMap>

                        </LoadScript>

                        <p className="mb-5 text-md text-start font-normal text-gray-500 ">Saved address</p>
                        <div className='scrollbar overflow-y-auto h-[60vh] w-full'>
                            {savedLocations ? savedLocations.map((location, index) => (
                                <div key={index} className='w-full bg-gray-100 mt-1 flex justify-between items-center p-3 rounded-md'>
                                    <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.8878 11.1658V11.1669C21.8872 12.9813 21.4061 14.7629 20.4939 16.3307L20.4858 16.341L20.4711 16.3631C19.9911 17.0801 19.4716 17.7698 18.9149 18.4289L18.9017 18.4445L18.8894 18.4608L12.5674 26.8302L12.5664 26.8316C12.4505 26.9858 12.3003 27.1109 12.1277 27.1971C11.9552 27.2833 11.7649 27.3281 11.572 27.3281C11.3791 27.3281 11.1889 27.2833 11.0163 27.1971C10.8437 27.1109 10.6936 26.9858 10.5777 26.8316L10.5766 26.8301L4.15462 18.3305C3.9271 18.0184 3.70006 17.741 3.49704 17.493C3.44328 17.4273 3.3912 17.3637 3.34124 17.302C3.0952 16.9983 2.88721 16.7265 2.70505 16.418L2.68171 16.3784L2.65372 16.342L2.65109 16.3386C1.86048 14.9805 1.39039 13.4597 1.27692 11.8919C1.16236 10.309 1.41446 8.72094 2.01363 7.25131C2.61281 5.78168 3.54291 4.47006 4.73159 3.41845C5.92027 2.36685 7.33549 1.60361 8.86721 1.18808C10.3989 0.772551 12.0059 0.715938 13.563 1.02264C15.1202 1.32934 16.5856 1.9911 17.8454 2.95644C19.1051 3.92178 20.1252 5.16469 20.8263 6.58851C21.5274 8.01233 21.8907 9.57868 21.8878 11.1658ZM8.44443 15.7907C9.3696 16.4081 10.4571 16.7373 11.5694 16.7365C13.06 16.7356 14.4893 16.1427 15.543 15.0883C16.5968 14.0339 17.1887 12.6043 17.1887 11.1136C17.1887 10.0013 16.8588 8.91403 16.2408 7.98925C15.6228 7.06448 14.7444 6.34378 13.7167 5.91829C12.689 5.49281 11.5583 5.38167 10.4674 5.59892C9.37656 5.81618 8.37465 6.35209 7.58841 7.13883C6.80217 7.92558 6.26691 8.92783 6.05036 10.0188C5.83381 11.1098 5.94569 12.2405 6.37184 13.2679C6.79798 14.2953 7.51926 15.1733 8.44443 15.7907Z" stroke="#696969" strokeWidth="1.5" />
                                    </svg>
                                    <div onClick={() => handelSubmit(location)} className='cursor-pointer'>
                                        <p className="mb-1 sm:text-sm text-md text-start font-normal text-gray-500 "><label className=''>{location?.frmData?.business ? location?.frmData?.business + ', ' : ''} {location?.frmData?.location ? location?.frmData?.location + ', ' : ''}</label>{location?.mpData?.name}</p>
                                    </div>
                                    {location.check ?
                                        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.78516 9.0798L8.6423 15.9369L22.3567 2.22266" stroke="#F95424" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        :
                                        <svg onClick={() => deleteLocation(index)} className=" cursor-pointer   " width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545"></path></svg>
                                    }
                                </div>
                            ))
                                :
                                <h3 className="p-3 text-xl text-center font-bold text-red-700  bg-red-100">Add address</h3>
                            }
                        </div>
                    </div>

                    : <div className="p-4 md:p-5 mt-2 ">
                        <div className="mb-2 w-full">
                            <label className="font-bold text-xl text-[#777777] mt-3 mb-2 ml-1">Add new address</label>
                            <div className='border-2 rounded px-2 py-1 border-gray-200 mt-2'>
                                <p className=" text-sm text-start font-normal text-gray-500 ">Address</p>
                                <p className=" text-md text-start font-normal text-gray-700 ">{mapData.name}</p>

                            </div>

                            <input id="loc" name="location"
                                value={formData.location} onChange={(e) => handleChange(e)}
                                className="w-full px-3 py-2 my-1 border-2 placeholder-gray-500 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="Apt, floor, suite, etc (optional)" type="text" />
                        </div>
                        {/* <div className="mb-2 w-full">
                            <input id="buss" name="business"
                                value={formData.business} onChange={(e) => handleChange(e)}
                                className="w-full px-3 py-2 mb-1 border-2 placeholder-gray-500 border-gray-200 rounded-md focus:outline-none focus:border-orange-500 transition-colors" placeholder="Business name (optional)" type="text" />
                        </div> */}

                        <LoadScript
                            googleMapsApiKey="AIzaSyDTF_IHcL1fDOskOfGnX_cYjcc2udxBfRU"
                            libraries={libraries}
                            loadingElement={<div>Loading...</div>}
                        >
                            <GoogleMap
                                id="search-box-example"
                                mapContainerStyle={{ height: '200px', width: '100%', borderRadius: '10px' }}
                                zoom={13}
                                center={mapData.location}
                            >
                                <Marker position={mapData.location} />
                            </GoogleMap>
                        </LoadScript>
                        <button
                            onClick={() => saveLocation()}
                            className={`block w-full mt-3 bg-orange-500 hover:bg-orange-700 focus:bg-orange-700 text-white rounded-lg p-3 font-semibold `}>
                            <i className="mdi mdi-lock-outline mr-1"></i>Saved Address
                        </button>
                    </div>}
            </div>
        </div>
    )
}