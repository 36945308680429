import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export default function Returns() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className=" text-start max-w-screen-xl">
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4 ">Our Philosophy</h1>
                <p className="mb-4">
                    We handle returns and exchanges on a case-by-case scenario with the objective of making our customers satisfied with their purchase from us. Please be patient as we kindly request customers to fully test out the product and offer troubleshooting tips for quick solutions.
                    Standard Returns
                </p>
                <p className="mb-4">
                    We gladly accepts returns of new, unopened items purchased on our website for a refund or store credit within 30 days of delivery. Returns that do not meet our policy will not be accepted and will be sent back to the original address of the order.
                </p>

                <h2 className="text-2xl font-bold mb-2">For sanitary and safety purposes, the following items are NON RETURNABLE  if opened or used:
                </h2>



                <ul className="list-disc list-inside mb-4">
                    <li>E-Liquids </li>
                    <li>Atomizers - such as RDA & Tanks </li>
                    <li>Disposable Vape </li>
                    <li>Replacement Pods & Coils - if temper foil is broken </li>
                    <li>Replacement Coils - if temper seal is broken </li>
                    <li>Spare Parts - Drip Tips, Glasses, O-Rings </li>
                    <li>Hemp Products </li>
                    <li>Rechargeable Batteries</li>
                </ul>

                <h2 className="text-2xl font-bold mb-2">How We Use the Information</h2>


                <p className="mb-4">
                    For product with special product seals, such as E-Liquid bottle cap or hardware packaging with tamper-proof stickers, it cannot be return once the seal is broken.
                    Warranty Service

                </p>
                <p className="mb-4">
                    Hardware device purchased directly from our website comes with a 30-Day Limited Warranty from the time of delivery. You may also contact manufacturers directly for After-Sales Service for extended warranty period.

                </p>
                <h2 className="text-2xl font-bold mb-2">Types of products not covered under our 30-Day Warranty:
                </h2>

                <ul className="list-disc list-inside mb-4">
                    <li>Atomizers - such as RDA & Tanks

                    </li>
                    <li>Disposable Vape
                    </li>
                    <li>Replacement Pods
                    </li>
                    <li>Replacement Coils
                    </li>
                    <li> Spare Parts - Drip Tips, Glasses, O-Rings

                    </li>
                    <li>Hemp Products
                    </li>
                    <li> Rechargeable Batteries
                    </li>
                </ul>
                <p className="mb-4">
                    In order to be eligible for service under warranty, you must initiate a returns process by contacting us at vapedeliveryerie@gmail.com or use the Contact Form within 30 days of original delivery. We do require the defective item to be shipped back to our facility prior to the issuance of a refund or store credit.
                </p>
                <p className="mb-4">
                    The 30-Day Limited Warranty excludes coverage for damage resulting from accidents, abuse, disassembly, customized modification, or user-error. Furthermore, consequential and incidental damages are not covered under this warranty.
                    Atomizers such as Tanks, RDA, and replacement parts (coils and pods) may not be covered as the original manufacturer does not provide warranty coverage on these types of item. You can visit the manufacturer's website for additional details.
                    Note: Some manufacturers does require the warranty card that is included in the package in order to process the warranty service. Please do not dispose of the packaging contents.
                    Delivery Issue Policy
                </p>
                <p className="mb-4">
                    Damaged, Missing or Wrong Item claims must be reported back to us within 48 hours from the time of delivery. Please inspect your items carefully upon receiving them, it is your responsibility to check within the time span and notify us of any problem. You may be requested to ship the incorrect items back to our facility and/or submit an Affidavit Form to proceed with the process.
                    Minor scratches, cosmetic issues, and discoloration are considered normal wear and tear and are not covered by warranty.
                    Additional Information

                </p>
                <p className="mb-4">
                    We do not guarantee compatibility between items. If you have any questions about compatibility, please do your research or send us a question before placing your order. We will not accept returns due to incompatibility.
                    If you would like to submit a request for refund, replacement or exchange, please contact our returns department by email at vapedeliveryerie@gmail.com. Please provide your FULL NAME, ORDER #, PHONE NUMBER, and REASONS for the return. You may be requested to provide pictures or video proof of the issue. If the return is approved, you will receive further instructions on returning the package.
                    Do not ship items back in a regular envelope or paper envelope material as this is not sufficient packaging and may cause items to be lost in the mail or damaged during transit. Padded envelopes or boxes are required. Poor packaging for the return of the products may result in refusal of your return.
                    Note: Atomizers such as tanks, RDAs, RTAs, RDTAs, pod systems, and starter kits purchased on our website are intended only for use with e-liquids and nicotine salts e-liquids. Any use of cannabis oil, cannabis-based substances, CBD oil, or similar liquids will void any warranty on these products and we will not accept it for a return.
                    This warranty give you specific legal rights, and you may also have other rights which vary from state to state.
                    Return Shipping Costs

                </p>
                <p className="mb-4">
                    In regards to the cost of shipment for returns, customer may be responsible for the costs of shipping the items back to us. We do not offer replacements as defective items will result in an issuance of credit back to your account. Adult Signature Service and all shipping charges are non-refundable.
                    Refund Process

                </p>
                <p className="mb-4">
                    Refund may be issued back to the original payment (Credit card, debit card, etc) or as store credit in the form of reward points. Vape Delivery is not responsible for refunds to a Prepaid Card or a cancelled credit card in the event the cardholder no longer has possession of.

                </p>




            </div>
        </div>
    )
}
